import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import Link from 'next/link' //'react-router-dom';
import { Dropdown } from 'react-bootstrap';
const team3 = 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';

import {AuthContext} from "~client/context/Auth";

const ProfileDropdown = () => {

  const authContext = useContext(AuthContext);

  console.log("at prof auth context");
  console.log(authContext);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        href="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={ authContext?.picture || "images/avatar.png" } 
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          
          {authContext.isAuthed &&
          <Dropdown.Item as={Link} onClick={()=>{authContext.dispatchAuthData({type:"logOut"})}} href="/">
            Logout
          </Dropdown.Item>}

          {!authContext.isAuthed &&
          <Dropdown.Item as={Link} href="/logIn">
            Log In
          </Dropdown.Item>}

        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
