import React, { useContext, useEffect } from 'react';
import AppContext from 'context/Context';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import {AuthContext} from "~client/context/Auth";
import LogInPage from "pages/logIn";

const MainLayout = (props) => {
  const authContext = useContext(AuthContext);

  console.log("AT MAINLAYOUT")
  console.log(authContext);


  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  /*useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);*/

  /*useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);*/

  let maxWidth = props?.maxWidth || 800;

  if (props.authenticationRequired) {
    if (!authContext.isAuthed) {
      if (!authContext.isAuthInitialized) {
        console.log("AUTH NOT INITIALIZED")



        return <p>{JSON.stringify(authContext)}</p> //null;
      }
      console.log("AUTH NOT AUTHED")
      return (
        <LogInPage />
      )
    }

  }

  const variants = {
    "scaleOut": { scale: 0.5, top: 0, transition: { type: "spring", bounce: .15, duration: .5 } },
    "xOut": { x: "-100%", transition: { type: "spring", delay: .5, duration: .5, bounce: .15 } },
    "scaleIn": { scale: 1, transition: { type: "spring", bounce: .15, duration: .5, delay: 0.5 } },
    "xIn": { x: 0, transition: { type: "spring", delay: 0, duration: .5, bounce: .15 } },
    "initial": { x: "100%", opacity: 1, scale: 0.85 },
    "animate": { x: 0, opacity: 1 }
  }


  return (
    <div className="container-fluid">
      <div className="content" style={{ maxWidth, marginLeft: "auto", marginRight: "auto" }}>
        <NavbarTop />
        {props.children}
        <Footer />
      </div>
    </div>
  )

  /*return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className="content" //className={classNames('content', { 'pb-0': isKanban })}
      >
        <NavbarTop />


        {props.children}

        {// </motion.div>
        }
        <Footer />
      </div>
    </div>
  );*/
};

export default MainLayout;

export function getAuthRequiredLayout(page) {
  return (
    <MainLayout authenticationRequired={true}>
      {page}
    </MainLayout>
  )
}

export function getLayout(page) {
  return (
    <MainLayout>
      {page}
    </MainLayout>
  )
}
