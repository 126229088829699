import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";

interface HistoryItem {
    path: string;
    name: string;
    current: boolean;
    uniqueKey: string;
}

function Separator() {
    return <span style={{paddingLeft:5,paddingRight:5}}></span>
}

function Item({ historyItem }: { historyItem: HistoryItem }) {

    let sep = <Separator />;
    if (historyItem.current) sep = null;

   // return <><span><Link href={historyItem.path}>{historyItem.name}</Link></span>{sep}</>;

    if (historyItem.current) return <span>{historyItem.name}</span>;
    return <><span><Link href={historyItem.path}>{historyItem.name}</Link></span>{sep}</>;

}

const PageTitles = {
    "/": "Home",
    "/serviceCall":"Service Calls",
    "/viewStore": "View Store",
    "/checkOutServiceCall": "Check Out of Service Call",
}

function createHistoryItem(router: NextRouter, historyItems: HistoryItem[], setHistoryItems: any) {

    let uniqueKey = router.asPath.split("#")[0];

    for (let i =0;i < historyItems.length;i++) {
        let historyItem = historyItems[i];
        if (historyItem.uniqueKey === uniqueKey) {
            if (!historyItem.current){
                historyItem.current = true;
                historyItems.splice(i);
                setHistoryItems(historyItems);
            }
            return
        }
    }


    for (let historyItem of historyItems) {
        if (historyItem.uniqueKey === uniqueKey) {
            return;
        }
    }

    let historyItem: HistoryItem = { uniqueKey, path: router.asPath, name: PageTitles[router.pathname] ||  router.pathname, current: true };
    for (let historyItem of historyItems) {
        if (historyItem.current) {
            historyItem.current = false;
        }
    }
    historyItems.push(historyItem);
    setHistoryItems(historyItems);
}

export default function BreadCrumb() {

    const router = useRouter();

    const [historyItems, setHistoryItems] = useState<HistoryItem[]>([{ uniqueKey: "/", path: "/", name: "Home", current: true }]);

    createHistoryItem(router, historyItems, setHistoryItems);

    /*useEffect(function () {

        const handleRouteChange = (url: string, { shallow }) => {

            console.log("route changed");
            console.log(url);
            console.log(shallow);

        }

        router.events.on("routeChangeComplete", handleRouteChange);

        return () => router.events.off("routeChangeComplete", handleRouteChange);
    })*/

    //router.reload();
    //router.back();
    //window.history.go(-2)//back 2 pages
    //router.push();//Change url to another next url. window.location is recommended for external urls
    //router.replace() / same as push but does not add to history
    //router.prefetch() / prefetches the page in the background, only needed if <Link> is not used
    //router.beforePopState() / called before navigating

    console.log("router...:");
    console.log(router);
    console.log(router.asPath);// /serviceCall?id=1&what=2#what
    console.log(router.basePath);
    console.log(router.pathname);// /serviceCall
    console.log(router.query);// {id: "1"}
    console.log(router.route);// /serviceCall
    console.log(router.locale);

    console.log("history");
    console.log(window.history);

    /*let HistoryItems: HistoryItem[] = [
        { path: "/", name: "Home", current: false },
        { path: "/serviceCall", name: "Service Calls", current: true }
    ];*/


    return (<div style={{paddingLeft:10}}>{
        historyItems.map((historyItem, index) => {
            return <Item key={index} historyItem={historyItem} />
        })
    }</div>)


}